import { createUseThemeHook, LegoColorPalette, LegoTheme } from 'legos/theme';

declare module 'legos/theme' {
  interface BulkLegoTheme {
    button: ButtonTheme;
  }
}

export interface ButtonVariant {
  lightNeutralColor: string;
  neutralColor: string;
  darkNeutralColor: string;
  lightColor: string;
  color: string;
  darkColor: string;
}

export interface ButtonTheme {
  default: ButtonVariant;
  accent: ButtonVariant;
  positive: ButtonVariant;
  negative: ButtonVariant;
  special: ButtonVariant;
  /** Sets the border radius for raised, flat and ghost buttons */
  borderRadius: string;
  /** Use a shadow when the button is inactive (not pressed), only for raised and FAB variants */
  hasShadows: boolean;
}

const mapColorForButton = (
  global: LegoTheme,
  color: LegoColorPalette
): ButtonVariant => ({
  lightNeutralColor: global.neutral.color100,
  neutralColor: global.neutral.color200,
  darkNeutralColor: global.neutral.color800,
  lightColor: color.color200,
  color: color.color500,
  darkColor: color.color600
});

const [ButtonThemeProvider, useButtonTheme] = createUseThemeHook(
  'button',
  (global): ButtonTheme => ({
    default: mapColorForButton(global, global.default),
    accent: mapColorForButton(global, global.accent),
    positive: mapColorForButton(global, global.positive),
    negative: mapColorForButton(global, global.negative),
    special: mapColorForButton(global, global.special),
    borderRadius: '2px',
    hasShadows: true
  })
);

export { ButtonThemeProvider, useButtonTheme };
