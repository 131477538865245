import { DefaultFonts, RawButton } from 'legos/typography';
import {
  ComponentType,
  createElement,
  FC,
  Fragment,
  MouseEvent,
  ReactNode
} from 'react';
import { ButtonTheme, useButtonTheme } from './button.theme';
import { BaseButton, FabIcon, BaseButtonPadding } from './button.ccm.css';

export interface ButtonProps {
  children: ReactNode;
  /**
   * Standard way to set the button look and feel.
   * @default default
   */
  color?: keyof Omit<ButtonTheme, 'borderRadius' | 'hasShadows'>;
  /**
   * Button style variant
   * @default 'raised'
   */
  variant?: 'fab' | 'miniFab' | 'ghost' | 'flat' | 'raised';
  /**
   * Set the button to disabled. Changes the look and color and makes the onClick not fire
   * @default false
   */
  disabled?: boolean;
  /**
   * Makes the button appear disabled, but allow it to still be enabled.
   *
   * Note: if `disabled` is set, then this will be `true` regardless of what is specified
   * @default false
   */
  disabledAppearance?: boolean;
  /**
   * Callback event to be notified when the button is clicked
   */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  /**
   * Href to set on the  event to be notified when the button is clicked.
   */
  href?: string;
  /**
   * Invert the color scheme of a fab button
   * @default false
   */
  inverted?: boolean;
  /**
   * Override the underlying component type, useful for making router links into buttons
   * @default button
   */
  component?: ComponentType;
  /**
   * Set the button type, useful to make it submit button
   * @default button
   */
  type?: JSX.IntrinsicElements['button']['type'];
  /**
   * Set the tab index
   * @default 0
   */
  tabIndex?: number;
  /**
   * Set the display type for button to block, useful for vartical set of buttons
   * @default false
   */
  block?: boolean;
}

export const Button: FC<ButtonProps> = ({
  children,
  component = 'button',
  type = 'button',
  color: colorStyle = 'default',
  variant = 'raised',
  block,
  href,
  inverted = false,
  disabled = false,
  disabledAppearance = false,
  tabIndex,
  onClick
}) => {
  const { borderRadius, hasShadows, ...buttonTheme } = useButtonTheme();

  const {
    color,
    neutralColor,
    lightNeutralColor,
    darkColor,
    lightColor,
    darkNeutralColor
  } = buttonTheme[colorStyle];

  const ButtonComponent = BaseButton.as(
    RawButton.as((component === 'button' && href ? 'a' : component) as any)
  );

  const ButtonTextWrapper =
    variant === 'fab' || variant === 'miniFab' ? FabIcon.div : Fragment;

  return (
    <ButtonComponent
      $color={color}
      $lightColor={lightColor}
      $darkColor={darkColor}
      $neutralColor={neutralColor}
      $lightNeutralColor={lightNeutralColor}
      $darkNeutralColor={darkNeutralColor}
      $borderRadius={borderRadius}
      $raised={variant === 'raised'}
      $flat={variant === 'flat'}
      $ghost={variant === 'ghost'}
      $fab={variant === 'fab'}
      $inverted={inverted}
      $miniFab={variant === 'miniFab'}
      $disabled={disabled || disabledAppearance}
      $shadows={hasShadows}
      $block={block}
      $fontFace={DefaultFonts}
      disabled={disabled}
      onClick={disabled ? undefined : onClick}
      tabIndex={tabIndex}
      href={href}
      role={'button'}
      type={type}
    >
      <BaseButtonPadding.div />
      <ButtonTextWrapper>{children}</ButtonTextWrapper>
    </ButtonComponent>
  );
};
